// $(function () {
//     var location = window.location.href;
//     var cur_url = '/' + location.split('/').pop();
 
//     $('.header__menu-list li').each(function () {
//         var link = $(this).find('a').attr('href');
 
//         if (cur_url == link) {
//             $(this).addClass('active-link');
//         }
//     });
// });

$(function(){
	$('.interface__item-image').click(function(event) {
	  var i_path = $(this).attr('src');
	  $('body').append('<div id="overlay"></div><div id="magnify"><img src="'+i_path+'"><div id="close-popup"><i></i></div></div>');
	  $('#magnify').css({
	   	left: ($(document).width() - $('#magnify').outerWidth())/2,
		top: ($(window).height() - $('#magnify').outerHeight())/170
	 });
	  $('#overlay, #magnify').fadeIn('fast');
	});
	
	$('body').on('click', '#close-popup, #overlay', function(event) {
	  event.preventDefault();
	  $('#overlay, #magnify').fadeOut('fast', function() {
		$('#close-popup, #magnify, #overlay').remove();
	  });
	});
  });

$(function() {
    $(".header__menu-link").click(function() {
        $(".header__menu-link").removeClass("active");         
        $(this).toggleClass("active");
    })
});

$('.popup').click(function(e) {
	var callbackPopup = $(".popup__container")
	if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
		$('body').removeClass('no-scroll');
		$(callbackPopup).closest(".popup").removeClass('active');
	}
});

$('.popup__close').click(function() {
	$('body').removeClass('no-scroll');
	$(this).closest('.popup').removeClass('active');
});

$('.product-card__order--1').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--1').addClass('active');
});

$('.product-card__order--2').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--2').addClass('active');
});

$('.product-card__order--3').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--3').addClass('active');
});


$(window).scroll(function() {
	if ($(this).scrollTop() > 100) {
		$('.to-top').addClass('scrolled');
	} else {
		$('.to-top').removeClass('scrolled');
	}
});

const headerHeight = $('.header').outerHeight();
$('a[href^="#"]').click(function() {
	let elementClick = $(this).attr("href").replace(/#/, '');
	if (elementClick && $('a[name=' + elementClick + ']').length) {
		let destination = $('a[name=' + elementClick + ']').offset().top;
		$('html, body').animate({ scrollTop: destination - headerHeight }, 200);
		return false;
	}
});

$('.header__burger').click(function() {
	$('body').addClass('no-scroll');
	$('.header__nav').addClass('active');
	$('.header__menu-link--about').addClass('active');
	$('.about-popup').slideDown(300);
});

$('.header__nav-close').click(function() {
	$('body').removeClass('no-scroll');
	$('.header__nav').removeClass('active');
	$('.header__menu-link--with-submenu').removeClass('active');
	$('.header__submenu').slideUp(300);
});

$('.header__menu-link--with-submenu').click(function(e) {
	e.preventDefault()
	$('.header__menu-link--with-submenu').not(this).removeClass('active');
	$('.header__menu-link--with-submenu').not(this).siblings('.header__submenu').slideUp(300);
	$(this).toggleClass('active');
	$(this).siblings('.header__submenu').slideToggle(300);
});

